import React from 'react'
import ccbyncnd from '../img/cc-by-nc-nd-88x31.png'

// Creative Commons By Attribution - Share Alike License
// Used primarily on blog

const Ccbysa = () => {
  return (
    <p>
      This page is licensed under a<br />
      <a target="_blank" rel="noopener noreferrer license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">
        Creative Commons Attribution-NonCommercial-NoDerivatives 4.0
        International License
      </a>
      . <br />
      <a target="_blank" rel="noopener noreferrer license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">
        <img alt="Creative Commons License" src={ccbyncnd} />
      </a>
    </p>
  )
}

export default Ccbysa
